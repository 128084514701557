import { render, staticRenderFns } from "./FolderManager.vue?vue&type=template&id=5e27bd8f&scoped=true&"
import script from "./FolderManager.vue?vue&type=script&lang=js&"
export * from "./FolderManager.vue?vue&type=script&lang=js&"
import style1 from "./FolderManager.vue?vue&type=style&index=1&id=5e27bd8f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e27bd8f",
  null
  
)

export default component.exports